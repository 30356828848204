<mat-toolbar
  role="heading"
  class="tw-border-b tw-border-gray-100 tw-shadow-sm tw-shadow-gray-100 tw-h-16 tw-w-full tw-z-30 tw-bg-white"
>
  <mat-toolbar-row class="!tw-px-0">
    <div
      class="tw-grid tw-grid-cols-[auto_1fr_auto] tw-z-20 tw-items-center tw-pr-4 tw-gap-6 tw-w-full tw-justify-between"
    >
      <!-- *ifViewportSize="'large'" -->
      <div class="tw-flex tw-gap-2 tw-items-center tw-w-96">
        <div class="tw-w-16 tw-flex tw-items-center tw-justify-center">
          <a [routerLink]="['/']" class="tw-w-6 tw-flex tw-items-center">
            <img src="/assets/logo/new-logo.svg" class="inline-flex" />
          </a>
        </div>

        <a [routerLink]="['/']" [class.active]="activeRouteGroup === 'home'" mat-button class="tw-group mat-primary">
          <span class="tw-text-gray-500 group-[.active]:tw-text-blue-500"> Hjem </span>
        </a>

        @if ((stores$ | async).length) {
          <a
            [routerLink]="['/ordrer']"
            [class.active]="activeRouteGroup === 'accounting'"
            mat-button
            class="tw-group mat-primary"
          >
            <span class="tw-text-gray-500 group-[.active]:tw-text-blue-500"> Bogføring </span>
          </a>
        } @else {
          <a
            [routerLink]="['/stores/sales-platform']"
            [class.active]="activeRouteGroup === 'accounting'"
            mat-stroked-button
            class="tw-group mat-primary"
          >
            <span class="tw-text-gray-500 group-[.active]:tw-text-blue-500"> Tilføj forretning </span>
          </a>
        }
      </div>

      <div class="tw-grow tw-flex tw-items-center tw-justify-center">
        <sb-search-bar></sb-search-bar>
      </div>

      <!-- *ifViewportSize="'large'" -->
      <div class="tw-flex tw-gap-2 tw-items-center tw-justify-end tw-w-96">
        <button
          mat-button
          [matMenuTriggerFor]="businessMenu"
          #businessMenuTrigger="matMenuTrigger"
          class="mat-primary !tw-text-gray-500"
        >
          <fa-icon class="tw-text-gray-500" [icon]="['fal', 'store']" size="xl" [fixedWidth]="true"></fa-icon>
          {{ (defaultStore$ | async)?.attributes.name || 'Alle' | middleEllipsis }}
          <fa-icon class="tw-text-gray-500" [icon]="['far', 'angle-down']"></fa-icon>
        </button>

        <mat-divider [vertical]="true" class="!border-gray-100 tw-h-8"></mat-divider>

        <a
          mat-button
          [href]="getHelpCenterSupportLink()"
          target="_blank"
          class="tw-group mat-primary !tw-min-w-0"
          matTooltip="Hjælp"
          aria-label="Hjælp"
        >
          <fa-icon [icon]="['fal', 'circle-question']" class="tw-text-gray-500" size="xl" [fixedWidth]="true"></fa-icon>
        </a>

        <button
          mat-button
          class="tw-group mat-primary !tw-min-w-0"
          matTooltip="Indstillinger"
          aria-label="Indstillinger"
          [matMenuTriggerFor]="accountMenu"
          #settingsTrigger="matMenuTrigger"
        >
          <fa-icon
            [icon]="['fal', 'building']"
            class="tw-text-gray-500"
            [class.!tw-text-blue-500]="settingsTrigger.menuOpen || activeRouteGroup === 'account'"
            size="xl"
            [fixedWidth]="true"
          ></fa-icon>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #accountMenu="matMenu" xPosition="before">
  <a mat-menu-item [routerLink]="['/konto/profil']" routerLinkActive="active"> Firma </a>
  <a mat-menu-item [routerLink]="['/konto/brugerprofil']" routerLinkActive="active"> Brugerprofil </a>
  <a mat-menu-item [routerLink]="['/konto/team']" routerLinkActive="active"> Team </a>
  @if ((hasOnlyShopifyBillingService$ | async) === false) {
    <a mat-menu-item [routerLink]="['/konto/betalingskort']" routerLinkActive="active"> Betalingskort </a>
    <a mat-menu-item [routerLink]="['/konto/fakturarer']" routerLinkActive="active"> Fakturaer </a>
  }
  <a mat-menu-item [routerLink]="['/konto/betingelser']" routerLinkActive="active"> Betingelser </a>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="logout()">Log ud</button>
</mat-menu>

<mat-menu #businessMenu="matMenu" xPosition="after">
  <button mat-menu-item (click)="onStoreSelectAction(null)" class="tw-inline">
    <span [class.!tw-text-blue-500]="(defaultStoreId$ | async) === null">Alle</span>
  </button>
  <ng-container *ngFor="let store of stores$ | async">
    <button
      mat-menu-item
      (click)="onStoreSelectAction(+store.id)"
      class="tw-group !tw-pr-1 !tw-w-64 [&_span]:!tw-w-full [&_span]:!tw-flex [&_span]:!tw-justify-between [&_span]:!tw-gap-1 [&_span]:!tw-items-center"
    >
      <span
        class="!tw-text-ellipsis !tw-overflow-hidden !tw-text-nowrap"
        [class.!tw-text-blue-500]="(defaultStoreId$ | async) === +store.id"
        >{{ store.attributes.name | firstUpperCase }}</span
      >
      <fa-icon
        *ngIf="store.attributes.version === 1"
        class="text-warning ms-2"
        [icon]="['fas', 'info-circle']"
        size="sm"
        [fixedWidth]="true"
        [transform]="'up-4'"
      ></fa-icon>
      <div class="tw-flex">
        <a
          mat-button
          class="mat-primary !tw-min-w-0"
          matTooltip="Indstillinger"
          aria-label="Indstillinger"
          (click)="
            $event.stopPropagation();
            onStoreSelectAction(+store.id, '/integrations/' + +store.id);
            businessMenuTrigger.closeMenu()
          "
        >
          <fa-icon
            [icon]="['fal', 'gear-complex']"
            [fixedWidth]="true"
            class="tw-text-gray-500 tw-opacity-0 group-hover:tw-opacity-100"
          ></fa-icon>
        </a>
      </div>
    </button>
  </ng-container>
  <mat-divider *ngIf="(stores$ | async).length > 0"></mat-divider>
  <a
    mat-menu-item
    [routerLink]="['/stores/sales-platform']"
    class="!tw-pr-2.5 [&_span]:!tw-whitespace-nowrap [&_span]:!tw-overflow-hidden [&_span]:!tw-overflow-ellipsis !tw-w-64 [&_span]:!tw-w-full [&_span]:!tw-flex [&_span]:!tw-justify-between [&_span]:!tw-gap-1 [&_span]:!tw-items-center"
  >
    <span class="me-3">Tilføj forretning</span>
    <fa-icon [icon]="['far', 'plus-circle']" [fixedWidth]="true" class="tw-text-gray-500"></fa-icon>
  </a>
</mat-menu>
