import { Component, HostBinding, Input, ElementRef, AfterViewInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-common-types';

interface LinkInput {
  text: string;
  url: string;
  type: 'href' | 'routerLink';
}

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'sb-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent {
  @Input() variant: 'primary' | 'accent' | 'info' | 'danger' | 'success' | 'basic' = 'basic';
  @Input() title?: string;
  @Input() message?: string;
  @Input() link?: LinkInput
  @Input() iconName?: IconName;
  @Input() primaryBtnText?: string;
  @Input() secondaryBtnText?: string;
  @Input() primaryBtnLoading?: boolean;
  @Input() secondaryBtnLoading?: boolean;
  
  @Output() primaryBtnAction? = new EventEmitter();
  @Output() secondaryBtnAction? = new EventEmitter();

  public primaryBtnClicked() {
    this.primaryBtnAction.emit();
  }
  public secondaryBtnClicked() {
    this.secondaryBtnAction.emit();
  }

  constructor() {}

  @HostBinding('class') get classList() {
    return `tw-flex tw-relative tw-items-center tw-rounded-lg tw-px-4 ${this.title && this.message ? 'tw-py-2' : 'tw-py-4'} tw-border tw-w-full ${this.getColorClasses()}`;
  }

  @HostBinding('role') get role() {
    return 'alert';
  }

  private getColorClasses() {
    switch (this.variant) {
      case 'primary':
        return 'tw-bg-primary-100 tw-text-primary-500 tw-border-primary-200';
      case 'accent':
        return 'tw-bg-accent-100 tw-text-accent-500 tw-border-accent-200';
      case 'info':
        return 'tw-bg-warn-100 tw-text-warn-500 tw-border-warn-200';
      case 'danger':
        return 'tw-bg-danger-100 tw-text-danger-500 tw-border-danger-200';
      case 'success':
        return 'tw-bg-success-100 tw-text-success-600 tw-border-success-200';
      case 'basic':
      default:
        return 'tw-bg-gray-100 tw-text-gray-500 tw-border-gray-200';
    }
  }
}
