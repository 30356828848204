import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'middleEllipsis',
})
export class MiddleEllipsisPipe implements PipeTransform {
  transform(value: string, length: number = 18): string {
    if (value.length <= length) {
      return value;
    }

    const start = value.substring(0, length / 2);
    const end = value.substring(value.length - length / 2, value.length);

    return `${start}...${end}`;
  }
}
