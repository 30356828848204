export const environment = {
  production: true,
  environment: 'production',
  apiUrl: 'https://api-master.storebuddy.io',
  appUrl: 'https://app.storebuddy.dk',
  apiLaravelUrl: 'https://api-laravel.storebuddy.io',
  auth0Domain: 'storebuddy.eu.auth0.com',
  auth0ClientId: '1FA6N85MnhnPUbPeb7mfKRAzSuDzo0aC',
  auth0Audience: 'https://api.storebuddy.dk',
  stripePubKey: 'pk_live_mkArfr4WQnCjlq4YgfA7JAN3',
  economicPubKey: 'QNfBQJjGPFthiTIRQarYtN1hfIJ8CJm6kJIk7xotFbU1',
  shopifyInstallLink: 'https://apps.shopify.com/storebuddy',
  shopifyPubKey: 'ab8d3cc67f97d329610ace098ff7dc97',
  stripeConnectKey: 'ca_ApccrdSHETf76Apb9i5HP5z8n06L85nQ',
  sentry: 'https://e86d5ce25b704dc8a9e034f77a4c86ab@o104421.ingest.sentry.io/259256',
  auth0Namespace: 'https://app.storebuddy.dk',
  version: 'c7ebf8f1cf66aa797d47f53687119dfed035b43c',
  voucherCdnHost: 'https://cdn-prod.storebuddy.io/pdf-invoice-creditnotes',
};
