import { Injectable } from '@angular/core';

const supportLinks = {
  mainLayout: {
    helpCenter: 'https://support.storebuddy.dk/da/knowledge',
  },
  settings: {
    integrations: {
      dandomain: ['https://storebuddy.dk/support/opret-en-dandomain-api-noegle/'],
      smartweb: ['https://support.storebuddy.dk/da/knowledge/opret-api-n%C3%B8gle-i-smartweb'],
      wannafind: ['https://support.storebuddy.dk/da/knowledge/opret-api-n%C3%B8gle-i-wannafind'],
      scannet: ['https://support.storebuddy.dk/da/knowledge/opret-api-n%C3%B8gle-i-scannet'],
      dandomain_2: ['https://support.storebuddy.dk/da/knowledge/opret-api-n%C3%B8gle-i-dandomain'],
      magento: ['https://support.storebuddy.dk/da/knowledge/magento'],
      magento2: ['https://storebuddy.dk/support/opret-magento-integrations-token/'],
      prestashop: ['https://storebuddy.dk/support/opret-prestashop-api-noegle/'],
      woocommerce: ['https://support.storebuddy.dk/da/knowledge/salgskanal#woocommerce'],
      shopify: ['https://support.storebuddy.dk/da/knowledge/integrerer-med-shopify'],
      quickpay: ['https://support.storebuddy.dk/hc/da/articles/360003769318'],
      altapay: ['https://support.storebuddy.dk/da/knowledge/integration-til-altapay'],
      swiipe: ['https://support.storebuddy.dk/da/knowledge/opret-swiipe-api-n%C3%B8gle'],
      onpay: ['https://support.storebuddy.dk/da/knowledge/integration-onpay'],
      billy: ['https://storebuddy.dk/support/opret-adgangsnogle-til-billy'],
      dinero: ['https://support.storebuddy.dk/da/knowledge/integration-til-dinero'],
      economic: ['https://support.storebuddy.dk/da/knowledge/integration-til-e-conomic'],
      freepay: ['#'],
      reepay: ['#'],
      stripe: ['https://support.storebuddy.dk/da/knowledge/integration-stripe'],
      clearhaus: ['https://support.storebuddy.dk/da/knowledge/integration-til-clearhaus'],
      nets: ['https://support.storebuddy.dk/da/knowledge/integration-til-nets-/-teller'],
      nets_easy: ['https://support.storebuddy.dk/da/knowledge/integration-til-nets-easy'],
      klarna: ['https://support.storebuddy.dk/da/knowledge/opret-klarna-api-/-integrationsn%C3%B8gler'],
      shopify_payments: ['https://support.storebuddy.dk/da/knowledge/integration-til-shopify-payments'],
      rapyd: ['#'],
      pensopay: ['https://support.storebuddy.dk'],
      zenegy: [''],
      sproom: [''],
      elavon: [''],
    },
  },
  postingBalance: {
    default: 'https://support.storebuddy.dk/da/knowledge/storebuddys-ordrebalance',
  },
};

@Injectable({
  providedIn: 'root',
})
export class SupportLinksService {
  constructor() {}

  getList() {
    return supportLinks;
  }
}
