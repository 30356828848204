import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Duotone
import { faCogs as fadCogs } from '@fortawesome/pro-duotone-svg-icons/faCogs';
import { faCreditCard as fadCreditCard } from '@fortawesome/pro-duotone-svg-icons/faCreditCard';
import { faGlobeEurope as fadGlobeEurope } from '@fortawesome/pro-duotone-svg-icons/faGlobeEurope';
import { faHandHoldingBox as fadHandHoldingBox } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingBox';
import { faHandHoldingUsd as fadHandHoldingUsd } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingUsd';
import { faHome as fadHome } from '@fortawesome/pro-duotone-svg-icons/faHome';
import { faInfoCircle as fadInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';
import { faPiggyBank as fadPiggyBank } from '@fortawesome/pro-duotone-svg-icons/faPiggyBank';
import { faQuestion as fadQuestion } from '@fortawesome/pro-duotone-svg-icons/faQuestion';
import { faShoppingCart as fadShoppingCart } from '@fortawesome/pro-duotone-svg-icons/faShoppingCart';
import { faStore as fadStore } from '@fortawesome/pro-duotone-svg-icons/faStore';
import { faUniversity as fadUniversity } from '@fortawesome/pro-duotone-svg-icons/faUniversity';
import { faUser as fadUser } from '@fortawesome/pro-duotone-svg-icons/faUser';
import { faExclamationTriangle as fadExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons/faExclamationTriangle';
import { faListCheck as fadListCheck } from '@fortawesome/pro-duotone-svg-icons/faListCheck';
import { faCartShopping as fadCartShopping } from '@fortawesome/pro-duotone-svg-icons/faCartShopping';
import { faChartNetwork as fadChartNetwork } from '@fortawesome/pro-duotone-svg-icons/faChartNetwork';

// Light
import { faAngleDown as falAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faBars as falBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faCalendar as falCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faCalendarEdit as falCalenderEdit } from '@fortawesome/pro-light-svg-icons/faCalendarEdit';
import { faCheckCircle as falCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faChevronLeft as falChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight as falChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faCircleNotch as falCircleNotch } from '@fortawesome/pro-light-svg-icons/faCircleNotch';
import { faClipboardCheck as falClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck';
import { faClock as falClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faCog as falCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faEdit as falEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faEraser as falEraser } from '@fortawesome/pro-light-svg-icons/faEraser';
import { faExclamationCircle as falExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faExternalLink as falExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faExternalLinkSquare as falExternalLinkSquare } from '@fortawesome/pro-light-svg-icons/faExternalLinkSquare';
import { faFilter as falFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faHistory as falHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
import { faHouse as falHouse } from '@fortawesome/pro-light-svg-icons/faHouse';
import { faInfoCircle as falInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faLightbulbOn as falLightbulbOn } from '@fortawesome/pro-light-svg-icons/faLightbulbOn';
import { faLock as falLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faLockOpen as falLockOpen } from '@fortawesome/pro-light-svg-icons/faLockOpen';
import { faMemoCircleInfo as falMemoCircleInfo } from '@fortawesome/pro-light-svg-icons/faMemoCircleInfo';
import { faPaperclip as falPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faPlay as falPlay } from '@fortawesome/pro-light-svg-icons/faPlay';
import { faPlayCircle as falPlayCircle } from '@fortawesome/pro-light-svg-icons/faPlayCircle';
import { faPlusCircle as falPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faScaleUnbalanced as falScaleUnbalanced } from '@fortawesome/pro-light-svg-icons/faScaleUnbalanced';
import { faSearchPlus as falSearchPlus } from '@fortawesome/pro-light-svg-icons/faSearchPlus';
import { faShoppingBasket as falShoppingBasket } from '@fortawesome/pro-light-svg-icons/faShoppingBasket';
import { faStopwatch as falStopwatch } from '@fortawesome/pro-light-svg-icons/faStopwatch';
import { faStore as falStore } from '@fortawesome/pro-light-svg-icons/faStore';
import { faSyncAlt as falSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faXmark as falXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faGear as falGear } from '@fortawesome/pro-light-svg-icons/faGear';
import { faTableColumns as falTableColumns } from '@fortawesome/pro-light-svg-icons/faTableColumns';
import { faCircleCheck as falCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck';
import { faFilePdf as falFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faDownload as falDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faReceipt as falReceipt } from '@fortawesome/pro-light-svg-icons/faReceipt';
import { faCircleQuestion as falCircleQuestion } from '@fortawesome/pro-light-svg-icons/faCircleQuestion';
import { faFileMagnifyingGlass as falFileMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faFileMagnifyingGlass';
import { faEye as falEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash as falEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faEllipsisVertical as falEllipsisVertical } from '@fortawesome/pro-light-svg-icons/faEllipsisVertical';
import { faTrash as falTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faCopy as falCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faAngleRight as falAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { faAngleLeft as falAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faDeleteLeft as falDeleteLeft } from '@fortawesome/pro-light-svg-icons/faDeleteLeft';
import { faArrowRightFromBracket as falArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons/faArrowRightFromBracket';
import { faWandMagicSparkles as falWandMagicSparkles } from '@fortawesome/pro-light-svg-icons/faWandMagicSparkles';
import { faListTree as falListTree } from '@fortawesome/pro-light-svg-icons/faListTree';
import { faLifeRing as falLifeRing } from '@fortawesome/pro-light-svg-icons/faLifeRing';
import { faPrint as falPrint } from '@fortawesome/pro-light-svg-icons/faPrint';
import { faSigma as falSigma } from '@fortawesome/pro-light-svg-icons/faSigma';
import { faUser as falUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faMoneyBillWave as falMoneyBillWave } from '@fortawesome/pro-light-svg-icons/faMoneyBillWave';
import { faMoneyCheckPen as falMoneyCheckPen } from '@fortawesome/pro-light-svg-icons/faMoneyCheckPen';
import { faCreditCardBlank as falCreditCardBlank } from '@fortawesome/pro-light-svg-icons/faCreditCardBlank';
import { faPiggyBank as falPiggyBank } from '@fortawesome/pro-light-svg-icons/faPiggyBank';
import { faMoneyBillTransfer as falMoneyBillTransfer } from '@fortawesome/pro-light-svg-icons/faMoneyBillTransfer';
import { faListCheck as falListCheck } from '@fortawesome/pro-light-svg-icons/faListCheck';
import { faGearComplex as falGearComplex } from '@fortawesome/pro-light-svg-icons/faGearComplex';
import { faArrowsRotate as falArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate';
import { faUserCircle as falUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import { faQuestionCircle as falQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faCircleExclamation as falCircleExclamation } from '@fortawesome/pro-light-svg-icons/faCircleExclamation';
import { faChevronDown as falChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChartMixed as falChartMixed } from '@fortawesome/pro-light-svg-icons/faChartMixed';
import { faScrewdriverWrench as falScrewdriverWrench } from '@fortawesome/pro-light-svg-icons/faScrewdriverWrench';
import { faBasketShopping as falBasketShopping } from '@fortawesome/pro-light-svg-icons/faBasketShopping';
import { faMagnifyingGlass as falMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faArrowTurnDownLeft as falArrowTurnDownLeft } from '@fortawesome/pro-light-svg-icons/faArrowTurnDownLeft';
import { faSpinnerThird as falSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import { faFileExport as falFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport';
import { faBuilding as falBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faTrashCan as falTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faArrowDown as falArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown';
import { faArrowRight as falArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faEllipsis as falEllipsis } from '@fortawesome/pro-light-svg-icons/faEllipsis';
import { faPlus as falPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faMinus as falMinus } from '@fortawesome/pro-light-svg-icons/faMinus';

// Regular
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faCheckCircle as farCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faCircleNotch as farCircleNotch } from '@fortawesome/pro-regular-svg-icons/faCircleNotch';
import { faCloudDownload as farCloudDownload } from '@fortawesome/pro-regular-svg-icons/faCloudDownload';
import { faCog as farCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { faExclamationCircle as farExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faFilter as farFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faHome as farHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import { faInfoCircle as farInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faLifeRing as farLifeRing } from '@fortawesome/pro-regular-svg-icons/faLifeRing';
import { faPaperclip as farPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import { faPlusCircle as farPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import { faSignOut as farSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';
import { faThumbtack as farThumbtack } from '@fortawesome/pro-regular-svg-icons/faThumbtack';
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faUserSecret as farUserSecret } from '@fortawesome/pro-regular-svg-icons/faUserSecret';
import { faToggleOn as farToggleOn } from '@fortawesome/pro-regular-svg-icons/faToggleOn';
import { faToggleOff as farToggleOff } from '@fortawesome/pro-regular-svg-icons/faToggleOff';
import { faXmark as farXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faFilePen as farFilePen } from '@fortawesome/pro-regular-svg-icons/faFilePen';
import { faPenToSquare as farPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { faGripDots as farGripDots } from '@fortawesome/pro-regular-svg-icons/faGripDots';
import { faTag as farTag } from '@fortawesome/pro-regular-svg-icons/faTag';
import { faBolt as farBolt } from '@fortawesome/pro-regular-svg-icons/faBolt';
import { faBoltSlash as farBoltSlash } from '@fortawesome/pro-regular-svg-icons/faBoltSlash';
import { faCodeFork as farCodeFork } from '@fortawesome/pro-regular-svg-icons/faCodeFork';
import { faBallotCheck as farBallotCheck } from '@fortawesome/pro-regular-svg-icons/faBallotCheck';
import { faTruckFast as farTruckFast } from '@fortawesome/pro-regular-svg-icons/faTruckFast';
import { faStore as farStore } from '@fortawesome/pro-regular-svg-icons/faStore';
import { faBan as farBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faCartShopping as farCartShopping } from '@fortawesome/pro-regular-svg-icons/faCartShopping';
import { faCalendarClock as farCalendarClock } from '@fortawesome/pro-regular-svg-icons/faCalendarClock';
import { faFileInvoiceDollar as farFileInvoiceDollar } from '@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar';
import { faOctagonExclamation as farOctagonExclamation } from '@fortawesome/pro-regular-svg-icons/faOctagonExclamation';
import { faPaperPlaneTop as farPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons/faPaperPlaneTop';
import { faPersonSimple as farPersonSimple } from '@fortawesome/pro-regular-svg-icons/faPersonSimple';
import { faFlag as farFlag } from '@fortawesome/pro-regular-svg-icons/faFlag';

// Solid
import { faChevronLeft as fasChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faCircleNotch as fasCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import { faExclamationCircle as fasExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faThumbtack as fasThumbtack } from '@fortawesome/pro-solid-svg-icons/faThumbtack';
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faMinus as fasMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faArrowUpRightFromSquare as fasArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class FontAwesomeImportModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faEllipsisV,
      farFilter,
      farLifeRing,
      farInfoCircle,
      farUserSecret,
      farCheckCircle,
      farXmark,
      farCheck,
      farExclamationCircle,
      farPlusCircle,
      farAngleDown,
      farCircleNotch,
      farThumbtack,
      farCog,
      farPaperclip,
      farHome,
      farSignOut,
      farCloudDownload,
      farTimes,
      farToggleOn,
      farToggleOff,
      farFilePen,
      farPenToSquare,
      farGripDots,
      farTag,
      farBolt,
      farBoltSlash,
      farCodeFork,
      farBallotCheck,
      farTruckFast,
      farStore,
      farBan,
      farCartShopping,
      farCalendarClock,
      farFileInvoiceDollar,
      farOctagonExclamation,
      farPaperPlaneTop,
      farPersonSimple,
      farFlag,
      fasInfoCircle,
      fasExclamationCircle,
      fasExclamationTriangle,
      fasChevronLeft,
      fasCircleNotch,
      fasThumbtack,
      fasPlus,
      fasMinus,
      fasArrowUpRightFromSquare,
      fadStore,
      fadUser,
      fadGlobeEurope,
      fadInfoCircle,
      fadHome,
      fadShoppingCart,
      fadUniversity,
      fadCreditCard,
      fadHandHoldingBox,
      fadHandHoldingUsd,
      fadPiggyBank,
      fadQuestion,
      fadCogs,
      fadExclamationTriangle,
      fadListCheck,
      fadCartShopping,
      fadChartNetwork,
      falPlusCircle,
      falClipboardCheck,
      falBars,
      falStopwatch,
      falCalenderEdit,
      falTimes,
      falShoppingBasket,
      falCog,
      falPaperclip,
      falHouse,
      falExternalLinkSquare,
      falChevronLeft,
      falChevronRight,
      falMemoCircleInfo,
      falCalendar,
      falScaleUnbalanced,
      falXmark,
      falGear,
      falTableColumns,
      falCircleCheck,
      falFilePdf,
      falDownload,
      falReceipt,
      falCircleQuestion,
      falFileMagnifyingGlass,
      falEye,
      falEllipsisVertical,
      falXmark,
      falTrash,
      falCopy,
      falAngleRight,
      falAngleLeft,
      falEyeSlash,
      falDeleteLeft,
      falArrowRightFromBracket,
      falWandMagicSparkles,
      falListTree,
      falLifeRing,
      falPrint,
      falExternalLink,
      falEraser,
      falSearchPlus,
      falLightbulbOn,
      falSyncAlt,
      falPlay,
      falPlayCircle,
      falInfoCircle,
      falCircleNotch,
      falCheckCircle,
      falExclamationCircle,
      falStore,
      falAngleDown,
      falEdit,
      falLock,
      falLockOpen,
      falHistory,
      falClock,
      falFilter,
      falSigma,
      falUser,
      falMoneyBillWave,
      falMoneyCheckPen,
      falPiggyBank,
      falMoneyBillTransfer,
      falListCheck,
      falGearComplex,
      falArrowsRotate,
      falUserCircle,
      falQuestionCircle,
      falCircleExclamation,
      falChevronDown,
      falChartMixed,
      falScrewdriverWrench,
      falBasketShopping,
      falMagnifyingGlass,
      falArrowTurnDownLeft,
      falCreditCardBlank,
      falSpinnerThird,
      falFileExport,
      falBuilding,
      falTrashCan,
      falArrowDown,
      falArrowRight,
      falEllipsis,
      falPlus,
      falMinus,
    );
  }
}
