import { Portal } from '@angular/cdk/portal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { MainState } from './main-store/main-state';
import { RightSideNavService } from './_shared/right-side-nav/services/right-side-nav.service';

@Component({
  selector: 'sb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('sideNav') sideNav: MatDrawer;

  public sideNavPortal: Portal<any>;
  public sideNavWidth = '75%';
  public sideNavBackdrop = true;

  constructor(
    public store: Store<MainState>,
    private _rightNavService: RightSideNavService,
    private _router: Router,
    private _window: Window,
  ) {}

  ngOnInit() {
    this.initiateStripe();

    this._rightNavService.currentPortal$
      .pipe(filter(({ portal }) => portal !== null))
      .subscribe(({ portal, width, backdrop }) => {
        this.sideNavPortal = portal;
        this.sideNavWidth = width ?? '75%';
        this.sideNavBackdrop = backdrop;
        this.sideNav.open();
      });

    this._rightNavService.close$.subscribe(() => {
      this.sideNav.close();
    });
  }

  onClose() {
    this._rightNavService.close();
  }

  initiateStripe() {
    (this._window as any).stripe = window.Stripe(environment.stripePubKey);
    (this._window as any).elements = window.stripe.elements();
  }
}
