import { State } from '@app/main-store/bootstrap.reducer';
import { StoreVersion } from '@app/_shared/enum/store-version.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ErrorObject, RequestState } from '@app/_shared/interfaces';

export const getBootstrapState = createFeatureSelector<State>('bootstrap');

export const getCompany = createSelector(getBootstrapState, (state: State) => state.company);

export const getStoreById = (id: number) =>
  createSelector(getBootstrapState, (state) => {
    return state.company.attributes.stores.find((_store) => _store.id === id) ?? null;
  });

/**
 * @deprecated Use getActiveStores instead
 */
export const getStoreList = createSelector(getBootstrapState, (state) => {
  return state.company.attributes.stores.filter((x) => !x.attributes.deleted);
});

export const getStores = createSelector(getBootstrapState, (state) => {
  return state.company?.attributes?.stores ?? [];
});

export const getPublicStores = createSelector(getStores, (stores) =>
  [...stores]
    .filter((store) => store.attributes.subscriptions?.attributes.is_subscription_externally_created)
    .sort((a, b) => +a.attributes.deleted - +b.attributes.deleted),
);

export const getActiveStores = createSelector(getStores, (stores) =>
  stores.filter(
    (store) =>
      !store.attributes.deleted && store.attributes.subscriptions?.attributes.is_subscription_externally_created,
  ),
);

export const storeAccess = (id: number) =>
  createSelector(getActiveStores, (stores) => {
    return stores.some((store) => +store.id === id);
  });

export const hasStore = createSelector(getBootstrapState, (state) => state.company?.attributes.stores.length > 0);

export const hasStoreVersionV2 = createSelector(getStores, (stores): boolean => {
  return stores.findIndex((store) => store.attributes.version === StoreVersion.V2) > -1;
});

export const getStoreVersionV2List = createSelector(getStores, (stores) => {
  return stores
    .filter((x) => x.attributes.version === StoreVersion.V2)
    .sort((a, b) => (a.attributes.deleted === b.attributes.deleted ? 0 : a.attributes.deleted ? 1 : -1));
});

export const getStoreNextReset = (props: { storeId: number }) =>
  createSelector(getBootstrapState, (state: State) => {
    const store = state.company.attributes.stores.find((x) => x.id === props.storeId);
    return store ? store.attributes.subscriptions.attributes.next_reset_at : null;
  });

export const hasActiveStores = createSelector(
  getStores,
  (stores) =>
    stores.filter(
      (x) =>
        x.attributes.deleted === false ||
        x.attributes.subscriptions.attributes.status === 'active' ||
        x.attributes.subscriptions.attributes.status === 'trialing',
    ).length > 0,
);

export const getDefaultStore = createSelector(getBootstrapState, (state) => {
  return state.company?.attributes.stores.find((store) => store.id === state.defaultStoreId);
});

export const isLoading = createSelector(getBootstrapState, (state): boolean => {
  return state.requestState === RequestState.LOADING;
});

export const isSaving = createSelector(getBootstrapState, (state): boolean => {
  return state.requestState === RequestState.SAVING;
});

export const isSaved = createSelector(getBootstrapState, (state): boolean => {
  return state.requestState === RequestState.SAVED;
});

export const getError = createSelector(getBootstrapState, (state): ErrorObject | null => {
  return (state.requestState as ErrorObject)?.message !== undefined ? (state.requestState as ErrorObject) : null;
});

export const hasShopifyBillingService = createSelector(getBootstrapState, (state) => {
  return !!state.company.attributes.stores.find(
    (store) => store.attributes.subscriptions?.attributes.billing_driver === 'shopify',
  );
});

export const hasOnlyShopifyBillingService = createSelector(getBootstrapState, (state) => {
  return !!state.company.attributes.stores.every(
    (store) => store.attributes.subscriptions?.attributes.billing_driver === 'shopify',
  );
});

export const hasFailedBookkeepingJob = createSelector(
  getCompany,
  (state) => state.attributes.failed_bookkeeping_outbox,
);
