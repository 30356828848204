@if (iconName) {
  <fa-icon [icon]="['fal', iconName]" size="2x" class="tw-mr-3"></fa-icon>
}

<div class="tw-flex tw-flex-col tw-align-middle tw-flex-grow">
  @if (title) {
    <div class="tw-font-bold tw-text-lg">{{ title }}</div>
  }

  @if (message || link) {
    <div class="tw-flex tw-items-center tw-justify-between tw-gap-x-3">
      @if (message) {
        <span class="tw-text-base">{{ message }}</span>
      }
      <div class="tw-flex tw-items-center tw-w-fit tw-gap-x-3">
        @if (link) {
          @if (link.type === 'routerLink') {
            <a class="tw-text-base tw-whitespace-nowrap" sbLink [routerLink]="link.url">{{ link.text }}</a>
          } @else {
            <a class="tw-text-base tw-whitespace-nowrap" sbLink [href]="link.url">{{ link.text }}</a>
          }
        }
        @if (secondaryBtnText) {
          <button
            mat-stroked-button
            class="mat-{{ variant === 'info' ? 'warn' : variant }}"
            (click)="secondaryBtnClicked()"
            [disabled]="secondaryBtnLoading"
          >
            @if (!secondaryBtnLoading) {
              {{ secondaryBtnText }}
            } @else {
              <fa-icon [icon]="['fas', 'circle-notch']" [spin]="true" [fixedWidth]="true"></fa-icon>
            }
          </button>
        }
        @if (primaryBtnText) {
          <button
            mat-flat-button
            class="mat-{{ variant === 'info' ? 'warn' : variant }}"
            (click)="primaryBtnClicked()"
            [disabled]="primaryBtnLoading"
          >
            <div class="tw-flex tw-items-center tw-justify-center">
              <span [class.tw-opacity-0]="primaryBtnLoading">{{ primaryBtnText }} </span>
              @if (primaryBtnLoading) {
                <fa-icon
                  class="tw-absolute"
                  [icon]="['fas', 'circle-notch']"
                  [spin]="true"
                  [fixedWidth]="true"
                ></fa-icon>
              }
            </div>
          </button>
        }
      </div>
    </div>
  }
</div>
<ng-content></ng-content>
