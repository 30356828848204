import { NgModule } from '@angular/core';
import { AlertComponent } from './alert.component';
import { FontAwesomeImportModule } from '@app/_shared/material/font-awesome-import.module';
import { LinkDirective } from '@app/_shared/directives/link.directive';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [AlertComponent],
  imports: [FontAwesomeImportModule, LinkDirective, MatButtonModule, RouterLink],
  exports: [AlertComponent],
})
export class AlertModule {}
