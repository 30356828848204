import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { BookkeepingStateBadgeModule } from '@app/_shared/bookkeeping-state-badge/bookkeeping-state-badge.module';
import { HeadingModule } from '@app/_shared/heading/heading.module';
import { NoStoreModule } from '@app/_shared/no-store/no-store.module';
import { CustomPipeModule } from '@app/_shared/pipes/custom-pipe.module';
import { DashboardComponent } from '@app/dashboard/dashboard.component';

import { MatCardModule } from '@angular/material/card';
import { StoreModule } from '@ngrx/store';
import * as fromDashboard from './store/dashboard.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DashboardEffects } from './store/dashboard.effects';
import { MatButtonModule } from '@angular/material/button';
import { AlertModule } from '@app/_shared/components/alert/alert.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CustomPipeModule,
    MatDividerModule,
    BookkeepingStateBadgeModule,
    HeadingModule,
    NoStoreModule,
    MatCardModule,
    StoreModule.forFeature(fromDashboard.dashboardFeatureKey, fromDashboard.reducer),
    EffectsModule.forFeature([DashboardEffects]),
    MatButtonModule,
    AlertModule
  ],
  declarations: [DashboardComponent],
})
export class DashboardModule {}
