<sb-toolbar [activeRouteGroup]="activeRouteGroup"></sb-toolbar>

<div class="tw-grid tw-grid-cols-[auto_1fr] tw-w-full">
  <sb-sidenav
    [hide]="activeRouteGroup !== 'accounting'"
    [animate]="activeRouteGroup !== 'accounting'"
    [menuItems]="sideNavItems"
  ></sb-sidenav>

  <div
    *ngIf="activeRouteGroup !== 'accounting'; else tabs"
    class="tw-h-[calc(100dvh-theme('spacing.16'))] tw-p-6 tw-overflow-auto tw-relative"
    cdkScrollable
  >
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #tabs>
  <div class="tw-h-[calc(100dvh-theme('spacing.16'))] tw-px-6 tw-overflow-auto tw-relative">
    <nav mat-tab-nav-bar mat-stretch-tabs="false" mat-align-tabs="start" [tabPanel]="tabPanel">
      <a
        mat-tab-link
        [routerLink]="[item.route]"
        *ngFor="let item of tabGroupItems"
        (click)="setActiveRoute(item.route)"
        [active]="isActiveRoute(item.route)"
      >
        {{ item.label }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</ng-template>
