import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IntegrationPlatform } from '@app/onboarding/store/integrations.service';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as OnboardingActions from '../../store/onboarding.actions';

@Component({
  selector: 'sb-sales-platform',
  templateUrl: './sales-platform.component.html',
  styleUrls: ['./sales-platform.component.scss'],
})
export class SalesPlatformComponent {
  public readonly salesPlatforms = [
    { id: 'shopify', title: 'Shopify' },
    { id: 'magento2', title: 'Magento 2' },
    { id: 'smartweb', title: 'SmartWeb' },
    { id: 'prestashop', title: 'PrestaShop' },
    { id: 'dandomain', title: 'Dandomain Classic' },
    { id: 'woocommerce', title: 'WooCommerce' },
    { id: 'scannet', title: 'ScanNet' },
    { id: 'magento', title: 'Magento' },
    { id: 'wannafind', title: 'Wannafind' },
    { id: 'dadndomain_2', title: 'Dandomain' },
  ];

  public readonly shopifyInstallLink = environment.shopifyInstallLink;

  constructor(
    private _router: Router,
    private _store: Store,
  ) {}

  public selectPlatform(integrationPlatform: IntegrationPlatform) {
    this._store.dispatch(OnboardingActions.setIntegrationPlatform({ integrationPlatform }));

    if (integrationPlatform === 'shopify') {
      this._router.navigate(['/stores/sales-platform/connect']);
    } else {
      this._router.navigate(['/stores/create']);
    }
  }
}
